

import { AppBase } from "@framework/app-base";
import { FetchContextImpl } from "@framework/fetch-context";
import { Decorator, DecoratorForm } from "@framework/component";
import { BitArray, ExceptionInterceptor, ResponseResult, storage } from "@framework/utils";
import { Button, Input, message, Modal } from "antd";
import { PUBLIC_KEY } from "./constants";

const FetchContext: FetchContextImpl = new FetchContextImpl(PUBLIC_KEY);

export const ResetPwdDecorators: Decorator[] = [{
    name: "password",
    className: "editor-form-item",
    element: <Input.Password placeholder="请输入新密码" />,
    rules: [{ type: "string", required: true, min: 8, max: 24, message: "请输入8-24位密码，允许包含大小写字母、数字、下划线以及特殊字符" }],
}, {
    name: "confirmPassword",
    dependencies: ["password"],
    element: <Input.Password placeholder="请输入确认密码" allowClear={true} />,
    updateProps: (decorator, values) => {
        const password = values[0];
        return {
            rules: decorator.hidden ? undefined : [{
                type: "string",
                required: true,
                validator: (_rule, value) => {
                    if (value !== password) {
                        return Promise.reject("两次输入的密码不一致！");
                    }
                    return Promise.resolve();
                }
            }]
        }
    }
}];

const ChangePwdDecorators: Decorator[] = [{
    name: "oldPassword",
    className: "editor-form-item",
    element: <Input.Password placeholder="请输入旧密码" />,
    rules: [{ required: true, min: 6, max: 24, message: "为确保本人操作，请输入旧登录密码" }],
}, {
    ...ResetPwdDecorators[0],
    dependencies: ["oldPassword"],
    updateProps: (_, values) => {
        const oldPassword = values[0];
        return {
            rules: [{
                type: "string",
                required: true,
                validator: (_rule, value) => {
                    if (value == null || value.length < 8 || value.length > 24) {
                        return Promise.reject("请输入8-24位密码，允许包含大小写字母、数字、下划线以及特殊字符");
                    }
                    if (oldPassword === value) {
                        return Promise.reject("新密码不能与原密码相同！");
                    }
                    return Promise.resolve();
                }
            }]
        }
    }
}, ResetPwdDecorators[1]]

class ExceptionHandleInterceptor extends ExceptionInterceptor {

    constructor() {
        super(0);
    }


    public process<T>(result: ResponseResult<T>, reason: Error): void | ResponseResult<T> {
        message.error(reason.message);
        if (result.response.status === 401) {
            const urlWithoutDomain = window.location.href.substring(window.location.origin.length);
            const params = new URLSearchParams();
            params.set("refer", urlWithoutDomain);
            const loginPath = storage.getStringFromLocal("loginPath")
            window.location.replace(`${loginPath ? loginPath : "/login"}?${params.toString()}`);
        }
    }
}

export const SIGNED_KEY = "@@Signed";

FetchContext.fetch.encrypt = true;

export const fetch = FetchContext.fetch;