

import * as Forge from "node-forge"

export type BytesEncodeMode = "Hex" | "Base64";

/**
 * 加密/解密工具。
 */
export class EncryptUtil {

    public static decryptByAes(keyBytes : string, bodyBytes : string, decodeMode : BytesEncodeMode = "Hex") : string {
        var cipher = Forge.cipher.createDecipher("AES-ECB", keyBytes);
        cipher.start();
        cipher.update(Forge.util.createBuffer(EncryptUtil.toBytes(bodyBytes, decodeMode), "raw"));
        cipher.finish();
        return cipher.output.toString();
    }

    public static encryptByAes(keyBytes : string, content : string, encodeMode : BytesEncodeMode = "Hex") : string {
        var cipher = Forge.cipher.createCipher("AES-ECB", keyBytes);
        cipher.start();
        cipher.update(Forge.util.createBuffer(content, "utf8"));
        cipher.finish();
        return EncryptUtil.toString(cipher.output.data, encodeMode);
    }

    public static encryptByRsa(publicKey : Forge.pki.rsa.PublicKey, content : string, encodeMode : BytesEncodeMode = "Hex") {
        let bytes : string = publicKey.encrypt(Forge.util.createBuffer(content, "utf8").data, "RSAES-PKCS1-V1_5");
        return EncryptUtil.toString(bytes, encodeMode);
    }
    
    public static getRandomKey() : string {
        return Forge.random.getBytesSync(16);
    }

    public static parsePublicKey(publicKey: string): Forge.pki.rsa.PublicKey {
        return Forge.pki.publicKeyFromPem(publicKey);
    }

    public static toBytes(content : string, mode : "Base64" | "Hex" = "Hex") : string {
        if (mode === "Hex") {
            return Forge.util.hexToBytes(content);
        }
        else {
            return atob(content);
        }
    }

    public static toString(bytes : string, mode : "Base64" | "Hex" = "Hex") : string {
        if (mode === "Hex") {
            return Forge.util.bytesToHex(bytes);
        }
        else {
            return Buffer.from(bytes, "base64").toString("utf-8");
        }
    }

    public static verify(publicKey : Forge.pki.rsa.PublicKey, content : string, signature : string, decodeMode : "Base64" | "Hex" = "Hex") : boolean {
        let md5ByteBuffer = Forge.md.md5.create().update(Forge.util.createBuffer(content, "utf8").bytes()).digest();
        return publicKey.verify(md5ByteBuffer.bytes(), EncryptUtil.toBytes(signature, decodeMode), "RSASSA-PKCS1-V1_5");
    }
}