
import { lazy, ReactNode, Suspense } from "react";
// 切换页面会出现闪屏现象
// 解决思路：公共页面不采用懒加载的方式 并在App.tsx去除Suspense的包裹
import AppLayout from "./layout";
import { RouteWithPermission } from "@framework/route-with-permission";

const Home = lazy(() => import("@pages/home"));
const List = lazy(() => import("@pages/list"));
const ActivityDetail = lazy(() => import("@pages/activity-detail"));
const RaceDetail = lazy(() => import("@pages/race-detail"));
const ProjectDetail = lazy(() => import("@pages/project-detail"));
const TeachingDetail = lazy(() => import("@pages/teaching-detail"));
const InformDetail = lazy(() => import("@pages/inform-detail"));
const DataDetail = lazy(() => import("@pages/data-detail"));

// const User = lazy(() => import('../User'));
// 实现懒加载的用Suspense包裹 定义函数
const lazyLoad = (children: ReactNode): ReactNode => {
    return <Suspense fallback={<h1>Loading...</h1>}>
        {children}
    </Suspense>
}

let index = 1;
export const routers: RouteWithPermission[] = [{
    key: index++,
    path: "app",
    element: (<AppLayout />),
    children: [{
        key: index++,
        index: true,
        element: (lazyLoad(<Home />))
    }, {
        key: index++,
        path: "activity",
        children: [{
            key: index++,
            index: true,
            element: (lazyLoad(<List type="activity" />))
        }, {
            key: index++,
            path: ":id",
            element: (lazyLoad(<ActivityDetail />))
        }]
    }, {
        key: index++,
        path: "race",
        children: [{
            key: index++,
            index: true,
            element: (lazyLoad(<List type="race" />))
        }, {
            key: index++,
            path: ":id",
            element: (lazyLoad(<RaceDetail />))
        }]
    }, {
        key: index++,
        path: "project",
        children: [{
            key: index++,
            index: true,
            element: (lazyLoad(<List type="project" />))
        }, {
            key: index++,
            path: ":id",
            element: (lazyLoad(<ProjectDetail />))
        }]
    }, {
        key: index++,
        path: "teaching",
        children: [{
            key: index++,
            index: true,
            element: (lazyLoad(<List type="teaching" />))
        }, {
            key: index++,
            path: ":id",
            element: (lazyLoad(<TeachingDetail />))
        }]
    }, {
        key: index++,
        path: "data",
        children: [{
            key: index++,
            index: true,
            element: (lazyLoad(<List type="data" />))
        }, {
            key: index++,
            path: ":id",
            element: (lazyLoad(<DataDetail />))
        }]
    }, {
        key: index++,
        path: "inform",
        children: [{
            key: index++,
            index: true,
            element: (lazyLoad(<List type="inform" />))
        }, {
            key: index++,
            path: ":id",
            element: (lazyLoad(<InformDetail />))
        }]
    }]
}];