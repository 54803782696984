import { fetch } from "@root/app";

export namespace HomeApi {

    export interface Banner {
        coverPath: string
        id: number
        name: string
        sort: number
        typeId?: number
        relevancyId?: string
    }

    export interface Activity {
        createTime: number
        enrollEndTime: number
        enrollStartTime: number
        enrollTypeName: string
        id: string
        imagePath: string
        lastUpdateTime: number
        name: string
        reviewStatus: number
        schoolId: string
        schoolName: string
        sort: number
        typeName: string
        userGroupName: string
    }

    export interface Data {
        createTime: number
        id: string
        lastUpdateTime: number
        name: string
        reviewStatus: number
        schoolId: string
        schoolName: string
        sort: number
        typeName: string
    }

    export interface Inform {
        createTime: number
        id: string
        lastUpdateTime: number
        reviewStatus: number
        schoolId: string
        schoolName: string
        sort: number
        title: string
    }

    export interface Project {
        createTime: number
        firstTeacherName: string
        id: string
        imagePath: string
        lastUpdateTime: number
        name: string
        principalName: string
        progressName: string
        recruitStatusName: string
        reviewStatus: number
        schoolId: string
        schoolName: string
        sort: number
        typeName: string
    }

    export interface Race {
        createTime: number
        enrollEndTime: number
        enrollStartTime: number
        id: string
        imagePath: string
        lastUpdateTime: number
        name: string
        reviewStatus: number
        schoolId: string
        schoolName: string
        sort: number
        typeName: string
    }

    export interface Teaching {
        cateName: string
        createTime: number
        id: string
        lastUpdateTime: number
        name: string
        principalName: string
        reviewStatus: number
        schoolId: string
        schoolName: string
        sort: number
        studyEndTime: number
        studyStartTime: number
    }

    export function getBanner() {
        return fetch.get<Banner[]>("/api/client/banner")
    }

    export function getActivity() {
        return fetch.get<Activity[]>("/api/client/activity/recommend")
    }

    export function getData() {
        return fetch.get<Data[]>("/api/client/learn-data/recommend")
    }

    export function getInform() {
        return fetch.get<Inform[]>("/api/client/notice/recommend")
    }

    export function getProject() {
        return fetch.get<Project[]>("/api/client/program/recommend")
    }

    export function getRace() {
        return fetch.get<Race[]>("/api/client/race/recommend")
    }

    export function getTeaching() {
        return fetch.get<Teaching[]>("/api/client/teaching-research/recommend")
    }
}