

import { IComparable, IComparer } from "./compare-ability";

export class SortedList<T> extends Array<T> {
    
    private comparer: IComparer<T>;

    constructor();

    constructor(comparable?: IComparer<T>);

    constructor(comparable?: IComparer<T>, ...items: T[]);

    constructor(comparable?: IComparer<T>, ...items: T[]) {
        super();
        if (comparable !== undefined) {
            this.comparer = comparable;
        } else {
            this.comparer = {
                compare(x: T, y: T): number {
                    return x > y ? 1 : (x < y ? -1 : 0);
                }
            };
        }
        Object.setPrototypeOf(this, SortedList.prototype);
        this.unshift(...items);
    }

    public push(item: T) {
        let index = this.search(item);
        if (index < 0) {
            super.splice(~index, 0, item);
        } else {
            super.splice(index + 1, 0, item);
        }
        return this.length;
    }

    public splice(start: number, deleteCount?: number, ...args: T[]): T[] {
        let deleted: T[] = super.splice(start, deleteCount);
        if (args != null && args.length > 0) {
            for (var item of args) {
                this.push(item);
            }
        }
        return deleted;
    }

    public search(item: T): number {
        let low = 0;
        let high = this.length - 1;
        let mid = 0;
        while (low <= high) {
            mid = (low + high) >> 1;
            let result = this.comparer.compare(item, this[mid]);
            if (result == 0) {
                return mid;
            }
            else if (result < 0) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }
        return ~low;
    }

    /**
     * 向列表中插入多条元素。
     * 
     * @param items 需添加的元素列表。
     */
    public unshift(...items: T[]) {
        for (var item of items) {
            this.push(item);
        }
        return this.length;
    }

    public sort(compareFn?: ((a: T, b: T) => number) | undefined): this {
        throw new Error("SortedList 不支持排序操作！");
    }
}