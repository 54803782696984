

type ReactKey = string | number
/**
 * 对两个对象进行浅比较，若相等则返回 true，否则返回 false。
 * @param {*} obj1 
 * @param {*} obj2 
 */
export const refrenceEquals = (obj1?: Record<string, any> | null, obj2?: Record<string, any> | null, excludeKeys?: (string | number | symbol)[]) => {
    if (obj1 == null && obj2 == null) {
        return true;
    }
    let keys = (obj1 == null ? [] : Object.keys(obj1)).concat(obj2 == null ? [] : Object.keys(obj2));
    if (excludeKeys != null && excludeKeys.length > 0) {
        let excludeMap = getExcludeMap(excludeKeys);
        keys = keys.filter(key => excludeMap[key] === undefined);
    }

    for (let key of keys) {
        let a = obj1 == null ? null : obj1[key];
        let b = obj2 == null ? null : obj2[key];
        if (a != b) {
            return false;
        }
    }
    return true;
}

const getExcludeMap = (excludeKeys: (string | number | symbol)[]) => {
    let exclude: Record<string | number | symbol, any> = {};
    excludeKeys.forEach(value => {
        exclude[value] = value;
    });
    return exclude;
}

const nullsFirst = <T>(x: T, y: T) => {
    if (x == null && y == null) {
        return 0;
    } else if (x == null) {
        return -1;
    } else if (y == null) {
        return 1;
    } else {
        return compare(x, y);
    }
}

const nullsLast = <T>(x: T, y: T) => {
    if (x == null && y == null) {
        return 0;
    } else if (x == null) {
        return 1;
    } else if (y == null) {
        return -1;
    } else {
        return compare(x, y);
    }
}

function compare<T>(x: T, y: T) {
    if (typeof x === "number" && typeof y === "number") {
        return x > y ? 1 : x < y ? -1 : 0;
    } else if (typeof x === "string") {
        return x.localeCompare(y as string);
    } else {
        throw new Error("不支持对字符串及数字以外的类型做比较！");
    }
}

/**
 * 将源数据转换为树形结构返回，{@param list} 中的元素需按层级顺序存放，即父级元素需出现在子级元素之前。
 * 
 * @param list 源数据列表。
 * @param keyOfId 源数据中的唯一标识。
 * @param keyOfParentId 源数据中的父级标识。
 * @param keyOfChildren 存放子级元素的属性名称。
 * @returns 若一个元素的 {@link keyOfParentId} 不为空，则将该元素添加到父元素的 {@link keyOfChildren} 列表中，并从最终列表中移除该元素（仅在父元素的 {@link keyOfChildren} 中保留该元素）。
 * @example
 * 源数据： 
 * let list = [
 *     { code: "00", name: "节点1"},
 *     { code: "0001", name: "节点1-1", parentCode: "00"},
 *     { code: "0002", name: "节点1-2", parentCode: "00"}, 
 *     { code: "0101", name: "节点2-1", parentCode: "01"}, 
 *     { code: "0102", name: "节点2-2", parentCode: "01"}, 
 *     { code: "0103", name: "节点2-3", parentCode: "01"}, 
 *     { code: "02", name: "节点3", parentCode: ""}, 
 *     { code: "0201", name: "节点3-1", parentCode: "02"}
 * ]
 * 
 * let reuslt = toTree(list, "code", "parentCode", "sublist");
 * 
 * result内容：
 * [
 *     { code: "00", name: "节点1", sublist: [{
 *         { code: "0001", name: "节点1-1", parentCode: "00"},
 *         { code: "0002", name: "节点1-2", parentCode: "00"},
 *     }]},
 *     { code: "0101", name: "节点2-1", parentCode: "01"}, 
 *     { code: "0102", name: "节点2-2", parentCode: "01"}, 
 *     { code: "0103", name: "节点2-3", parentCode: "01"}, 
 *     { code: "02", name: "节点1", sublist: [{
 *         { code: "0201", name: "节点2-1", parentCode: "02"},
 *     }]}
 * ]
 */
export function toTree<T extends any>(list: Array<T>, keyOfId: keyof T, keyOfParentId: keyof T, keyOfChildren: keyof T, sorted?: boolean) {
    if (list == null) {
        return [];
    }
    let array = list as Required<T>[];

    let result: Array<T> = [];

    let map: any = {};
    if (sorted != true) {
        list.sort((a, b) => {
            let result = nullsFirst(a[keyOfParentId], b[keyOfParentId]);
            return result == 0 ? nullsLast(a[keyOfId], b[keyOfId]) : result;
        });
    }

    for (let i = 0; i < array.length; i++) {
        let cate = array[i];
        let id = cate[keyOfId];
        let parentId = cate[keyOfParentId];

        map[id] = cate;
        if (parentId != null && map[parentId] != null && map[parentId] != cate) {
            if (map[parentId][keyOfChildren] == null) {
                map[parentId][keyOfChildren] = [];
            }
            map[parentId][keyOfChildren].push(cate);
        } else {
            result.push(cate);
        }
    }
    return result;
}

function getKeyValue<T>(record: T, keyOfId: keyof T | ((record: T, index?: number) => ReactKey)) {
    if (keyOfId instanceof Function) {
        return keyOfId(record);
    } else {
        return record[keyOfId] as ReactKey;
    }
}

export function treeToMap<T>(tree: Array<T>, keyOfId: keyof T | ((record: T, index?: number) => ReactKey), keyOfChildren: keyof T) {
    const result: Record<string | number, T> = {};
    for (const node of tree) {
        appendNodeToMap(node, keyOfId, keyOfChildren, result);
    }
    return result;
}

function appendNodeToMap<T>(node: T, keyOfId: keyof T | ((record: T, index?: number) => ReactKey), keyOfChildren: keyof T, result: Record<ReactKey, T>) {
    result[getKeyValue(node, keyOfId)] = node;
    if (node[keyOfChildren] == null || !(node[keyOfChildren] instanceof Array)) {
        return;
    }
    for (let childNode of node[keyOfChildren] as Array<T>) {
        appendNodeToMap(childNode, keyOfId, keyOfChildren, result)
    }
}

export function getAllChildren<T>(node: T, keyOfChildren: keyof T, result: Array<T>) {
    result.push(node);
    if (node[keyOfChildren] != null && node[keyOfChildren] instanceof Array) {
        for (const child of node[keyOfChildren] as Array<T>) {
            getAllChildren(child, keyOfChildren, result);
        }
    }
}

/**
 * 合并两个{@link Object}到一个新的{@link Object}，对于 {@link params} 中的 {@link Object}
 * （不包括 {@link Function} 及 {@link Array}）属性，首先对 {@link source} 中的同名属性执行深拷贝，然后与 {@link params} 中的属性合并。
 * 
 * @param source 源对象，保留此对象中存在但 {@link params} 中不存在的属性。
 * @param params 合并对象，保留此对象中的所有属性。
 * @returns 返回合并后的对象。
 */
export function mergeProps<T>(source: T, params?: Partial<T>, isInnerProp?: boolean): T | undefined {
    if (params == null) {
        return undefined;
    }
    let result: T = Object.assign({}, source);
    for (const key in params) {
        if (!(result[key] instanceof Array) && !(result[key] instanceof Function) && result[key] instanceof Object) {
            (result as Partial<T>)[key] = mergeProps(result[key], params[key], true);
        } else if ((params as Object).hasOwnProperty(key)) {
            result[key] = (params as T)[key];
        }
    }
    if (isInnerProp) {
        for (const key in result) {
            if (!params.hasOwnProperty(key)) {
                delete params[key];
            }
        }
    }
    return result;
}

/**
 * 粗略判断 {@link value} 是否为 json 字符串。
 * 
 * @param value 需判断是否为 json 字符串的变量。
 * @returns 若 {@link value} 是 json 字符串或 {@link value} 为 null、undefined 时返回 true；否则，返回 false。
 */
export function isJsonStringUnsafe(value?: any): boolean {
    if (value == null) {
        return true;
    }
    if (typeof value !== "string") {
        return false;
    }
    return ((value.startsWith("{") && value.endsWith("}")) || (value.startsWith("[") && value.endsWith("]")));
}

export const downloadExport = async (fetch: Function, payload: any, title: string = "导出信息") => {
    const blob = await fetch(payload)
    const a = document.createElement("a")
    a.href = URL.createObjectURL(blob)
    a.target = "blank"
    a.download = title
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    URL.revokeObjectURL(a.href)
}