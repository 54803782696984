

import { pki } from "node-forge";

import { DEFAULT_ERROR_CODE } from "./constants";
import { storage, BytesEncodeMode, EncryptUtil, RequestInfo, ResponseResult, RequestInterceptor, ResponseInterceptor, isJsonStringUnsafe } from "./utils";


const TOKEN_KEY = "@@TOKEN";
/**
 * 响应数据解密拦截器。
 */
export class DecryptInterceptor extends ResponseInterceptor {

    private bytesEncodeMode: BytesEncodeMode;
    private publicKey: pki.rsa.PublicKey;

    constructor(bytesEncodeMode: BytesEncodeMode = "Hex", publicKey: pki.rsa.PublicKey) {
        super(Number.MIN_SAFE_INTEGER);
        this.bytesEncodeMode = bytesEncodeMode;
        this.publicKey = publicKey;
    }

    process<T>(result: ResponseResult<T>): ResponseResult<T> | Promise<ResponseResult<T>> {
        let response = result.response;
        let key = result?.key == null ? "" : result.key;
        result.key = undefined;
        if (response == null || !response.ok) {
            return result;
        }
        let authorization = response.headers.get("Authorization");
        let contentType = response.headers.get("Content-Type");
        let str = response.headers.get("X-Signature");
        let signature = str === null ? "" : str;
        if (authorization !== null) {
            if (result.encrypt) {
                authorization = EncryptUtil.decryptByAes(key, authorization, this.bytesEncodeMode);
            }
            storage.saveInLocal(TOKEN_KEY, authorization)
        }

        if (contentType == null || (!contentType.startsWith("text") && !contentType.startsWith("application/json"))) {
            return result;
        }
        if (result.originData != null) {
            let text = result.originData;
            if (result.encrypt && text != null && text != "") {
                text = EncryptUtil.decryptByAes(key, text, this.bytesEncodeMode);
                if (!EncryptUtil.verify(this.publicKey, text, signature, this.bytesEncodeMode)) {
                    result.code = DEFAULT_ERROR_CODE;
                    result.message = "签名校验失败！";
                    throw new Error(result.message);
                } else {
                    result.originData = text;
                }
            }
            if (result.originData != null && isJsonStringUnsafe(result.originData)) {
                result.data = JSON.parse(result.originData);
            } else {
                result.data = result.originData as T;
                console.warn(`${result.originData}不是有效的json字符串！`)
            }
            result.info = result.data;
            return result;
        } else {
            throw new Error("获取响应数据时发生错误！");
        }
    }
}

/**
 * 请求数据加密拦截器。
 */
export class EncryptInterceptor extends RequestInterceptor {
    private publicKey: pki.rsa.PublicKey;
    private bytesEncodeMode: BytesEncodeMode;

    constructor(bytesEncodeMode: BytesEncodeMode = "Hex", publicKey: pki.rsa.PublicKey) {
        super(0);
        this.bytesEncodeMode = bytesEncodeMode;
        this.publicKey = publicKey;
    }

    public override process(request: RequestInfo): RequestInfo {
        const headers = request.headers == undefined ? {} as Record<string, string> : request.headers as Record<string, string>;
        const authorization: Record<string, any> = {
            timestamp: new Date().getTime(),
            nonce: EncryptUtil.toString(EncryptUtil.getRandomKey(), this.bytesEncodeMode),
            token: storage.getStringFromLocal(TOKEN_KEY, "")
        }
        let authHeader = "";
        if (request.encrypt) {
            const key = request.key;
            authorization["key"] = EncryptUtil.toString(key as string, this.bytesEncodeMode);
            authHeader = JSON.stringify(authorization);
            authHeader = EncryptUtil.encryptByRsa(this.publicKey, authHeader, this.bytesEncodeMode);
            if (request.body != null && !(request.body instanceof FormData)) {
                request.body = EncryptUtil.encryptByAes(key as string, request.body as string, this.bytesEncodeMode);
            }
        } else {
            authHeader = JSON.stringify(authorization);
        }
        headers["Authorization"] = authHeader;
        request.headers = headers;
        return request;
    }
}

/**
 * 动态权限验证拦截器。
 */
export class DynamicAuthorizeInterceptor extends ResponseInterceptor {
    public constructor() {
        super(Number.MIN_SAFE_INTEGER);
    }

    public override process<T>(result: ResponseResult<T>): ResponseResult<T> | Promise<ResponseResult<T>> {

        if (!result.needAuthorize && !result.needConfirm) {
            return result;
        }

        return new Promise((resolve, reject) => {

            return result;
            //     let iform = null
            //     const dispatchAuthorize = (values, modalClose) => {
            //         AppContext.Current.store.dispatch(authorizePermission(values)).then((orderInfo) => {
            //             modalClose()
            //             resolve(orderInfo)
            //         }, (message) => {
            //             modalClose()
            //             reject(message)
            //         })
            //     }

            //     Modal.confirm({
            //         title: '动态授权验证',
            //         content: isComfirmAuthed
            //             ? '您已具有当前权限，确认继续操作吗？'
            //             : <NormalForm
            //   noSubmit
            //   wrappedComponentRef={ inst => iform = inst }
            //   decorators={
            //             [{
            //                 field: "loginName",
            //                 label: "授权用户",
            //                 element: <Input />,
            //     rules: [{ required: true, message: "请输入授权用户名" }]
            //             }, {
            //                 field: "password",
            //                 label: "授权密码",
            //                 element: <Input type="password" />,
            //     rules: [{ required: true, message: "请输入授权用户密码" }]
            //             }]}
            //         />,
            //         onOk: (modalClose) => {
            //             if (isComfirmAuthed) {
            //                 return dispatchAuthorize(null, modalClose)
            //             } else {
            //                 const validatePromise = new Promise((vResolve, vReject) => {
            //                     iform.props.form.validateFields((err, values) => {
            //                         if (err) return vReject()
            //                         vResolve(values)
            //                     })
            //                 })
            //                 return validatePromise.then(values => dispatchAuthorize(values, modalClose))
            //             }
            //         },
            //         onCancel: () => {
            //             reject()
            //         }
            //     })
        })
    }
}

/**
 * 响应解析拦截器。
 */
export class ParseResponseInterceptor extends ResponseInterceptor {

    public constructor() {
        super(Number.MIN_SAFE_INTEGER);
    }

    public override process<T>(result: ResponseResult<T>): ResponseResult<T> | Promise<ResponseResult<T>> {
        let response = result.response;

        result.code = response.status;
        switch (response.status) {
            case 201:
            case 200: {
                result.code = 0;
                break;
            }
            case 204: {
                result.code = 0;
                result.message = "操作成功！";
                break;
            }
            case 302:
            case 303:
                result.code = 0;
                break;
            case 504: {
                result.message = "网络超时，请检查您的网络配置，或联系开发人员";
                break;
            }
            case 500: {
                result.message = '不好意思，服务器出错了'
                break
            }
        }

        const contentType = response.headers.get("content-type");
        if (contentType == null || contentType.indexOf("text") >= 0 || contentType.indexOf("json") >= 0) {
            return response.text().then(text => {
                if (!response.ok) {
                    result.message = text == null || text == "" ? result.message : text;
                    throw new Error(result.message);
                } else {
                    result.originData = text;
                    return result;
                }
            });
        } else {
            return response.blob().then(blob => {
                result.data = blob as T;
                return result;
            });
        }
    }
}