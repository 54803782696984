

import moment from "moment";


export function dateFormat (value?: string | number, fallback: string = "--") {
    return formatRender(value, "YYYY-MM-DD", fallback);
}

export const dateRender = dateFormat;

export function dateTimeFormat(value?: string | number, fallback: string = "--") {
    return formatRender(value, "YYYY-MM-DD HH:mm", fallback);
}

export const dateTimeRender = dateTimeFormat;

export const formatDate = (value?: string | number, format: string = "YYYY-MM-DD HH:mm", defaultValue: string = "--") => {
    const num = Number(value);
    if (Number.isNaN(num)) {
        return defaultValue;
    }
    return moment(num).format(format);
}

export const formatDateRender = formatDate;
export const formatRender = formatDate;


export const timeFormat = (value: string | number, defaultValue: string = "--") => {
    return formatRender(value, "HH:mm:ss", defaultValue);
}

export const timeRender = timeFormat;