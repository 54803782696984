import { fetch } from "@root/app";
import { PageResult } from "@root/framework/utils";
import { HomeApi } from "./home";

export namespace ListApi {

    export interface QueryParams {
        limit: number
        skip: number
        name: string
        title?: string
    }

    export function getActivityList(params: QueryParams) {
        return fetch.get<PageResult<HomeApi.Activity>>("/api/client/activity", params)
    }

    export function getDataList(params: QueryParams) {
        return fetch.get<PageResult<HomeApi.Data>>("/api/client/learn-data", params)
    }

    export function getInformList(params: QueryParams) {
        return fetch.get<PageResult<HomeApi.Inform>>("/api/client/notice", params)
    }

    export function getProjectList(params: QueryParams) {
        return fetch.get<PageResult<HomeApi.Project>>("/api/client/program", params)
    }

    export function getTeachingList(params: QueryParams) {
        return fetch.get<PageResult<HomeApi.Teaching>>("/api/client/teaching-research", params)
    }

    export function getRaceList(params: QueryParams) {
        return fetch.get<PageResult<HomeApi.Race>>("/api/client/race", params)
    }
}