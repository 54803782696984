

import { DrawerProps, ModalProps } from "antd";
import { PermissionRequire } from "./permission-require";
import { BitArray } from "./utils";
import { StepsFormModalProps } from "./component";
import { Location, Navigate, NavigateFunction, Outlet, Params, To, useHref, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RouteWithPermission } from "./route-with-permission";

export interface ModalUpdater<T extends ModalProps> {
    currentProps?: T
    destroy: (() => void),
    update: (props: Partial<T>) => void
    /**
     * 模态对话框 {@link ModalProps#onOk} 方法的返回结果。
     */
    modalResult?: any
}

type Resolve<T> = (value: T | PromiseLike<T>) => void;
type Reject = (reason: any) => void;

interface Callback<T> {
    resolved?: (value: T) => void
    rejected?: (reason: any) => void
}

export async function awaitLoop<T, M extends ModalProps>(awaiter: ModalPromise<T, M> | (() => ModalPromise<T, M>)) {
    const promise = awaiter instanceof Function ? awaiter() : awaiter;
    for (; ;) {
        try {
            await promise;
            break;
        } catch (error) {
            promise.reset();
        }
    }
}

declare global {
    var global: typeof globalThis
    var app: AppBase<any>
}

if (global == null) {
    Object.defineProperty(Object.prototype, "global", {
        get() {
            return this;
        }
    });
}

export class ModalPromise<T, M extends ModalProps> implements PromiseLike<T> {
    updater?: ModalUpdater<M>
    private status: "pending" | "fullfilled" | "rejected";
    private result: any;
    private index: number;

    private parent?: ModalPromise<any, M>;
    private callbacks: Callback<T>[];

    constructor(executor: (resolve: Resolve<T>, reject: Reject) => void, parent?: ModalPromise<any, M>) {
        this.status = "pending";
        this.callbacks = [];
        this.index = 0;
        this.parent = parent;
        executor(this.onfullfilled, this.onrejected);
    }

    private onfullfilled = (value: T | PromiseLike<T>) => {
        if (this.status !== "pending") {
            return;
        }
        this.status = "fullfilled";
        Promise.resolve(value).then(val => {
            this.result = val;
            this.doSuccess(val);
        });
    }

    private onrejected = (reason: any) => {
        // 状态只能改变一次，判断不是pending，就直接结束
        if (this.status !== "pending") {
            return;
        }
        this.status = "rejected";

        this.result = reason;
        this.doFail(reason);
    }

    private doSuccess = (value: T) => {
        for (; this.index < this.callbacks.length; this.index++) {
            const resolved = this.callbacks[this.index].resolved;
            if (resolved) {
                resolved(value);
            }
        }
    }

    private doFail = (reason: any) => {
        try {
            for (; this.index < this.callbacks.length; this.index++) {
                const rejected = this.callbacks[this.index].rejected;
                if (rejected) {
                    rejected(reason);
                }
            }
        } finally {
            this.reset();
        }
    }

    public reset() {
        this.index = 0;
        this.status = "pending";
        if (this.parent != null) {
            this.parent.reset();
        }
    }


    then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined): Promise<TResult1 | TResult2> {
        let promise2 = new ModalPromise<TResult1 | TResult2, M>((resolve, reject) => {
            const callback: Required<Callback<T>> = {
                resolved: (value) => {
                    if (onfulfilled == null) {
                        resolve(value as any);
                    } else {
                        Promise.resolve(onfulfilled(value)).then(val => {
                            resolve(val);
                        }).catch(reason => {
                            reject(reason);
                        });
                    }
                },
                rejected: (reason) => {
                    if (onrejected == null) {
                        reject(reason);
                    } else {
                        resolve(onrejected(reason));
                    }
                }
            };

            if (this.status == "fullfilled") {
                this.index++;
                callback.resolved(this.result);
            } else if (this.status == "rejected") {
                this.index++;
                callback.rejected(this.result);
            } else if (this.status == "pending") {

            }
            this.callbacks.push(callback);
        }, this);
        return promise2;
    }

    catch<TResult1>(onrejected?: ((reason: any) => TResult1 | PromiseLike<TResult1>) | null | undefined) {
        return this.then(undefined, onrejected);
    }

    finally(onfinally?: (() => void) | null | undefined): Promise<any> {
        return this.then<void, void>(onfinally && ((value) => onfinally()), onfinally && ((reason) => onfinally()));
    }

    [Symbol.toStringTag] = "PromiseLike";
}

class SearchParams extends URLSearchParams {
    constructor(init?: URLSearchParams) {
        super(init);
    }
    
    getNumber(name: string, defaultValue: number = 0) {
        const str = this.get(name);
        const value =  Number(str);
        if (!str || Number.isNaN(value)) {
            return defaultValue == null ? 0 : defaultValue;
        }
        return value;
    }
}


export abstract class AppBase<TUser> {

    static Instance: AppBase<any>;

    private _pathParams?: Readonly<Params<string>>;
    private _queryParams?: SearchParams;
    private _location?: Location;
    private _navigator?: NavigateFunction;

    private _stepModals: Array<StepsFormModalProps>;
    private _modals: Array<ModalProps>;
    private _drawer?: DrawerProps;

    get drawer() {
        return this._drawer;
    }

    get modals() {
        return this._modals;
    }

    get stepModals() {
        return this._stepModals;
    }

    abstract get permissions(): BitArray;

    abstract get currentUser(): TUser;

    abstract get mapAppKey(): string;

    abstract get mapSecurity(): string;

    get pathParams(): Readonly<Params<string>> {
        if (this._pathParams == null) {
            console.warn("使用 pathParams 属性，需要在render方法中增加节点 <UseRouter attach={this} /> 。");
            return {};
        }
         return this._pathParams;
    }


    set pathParams(pathParams: Readonly<Params<string>>) {
        this._pathParams = pathParams;
    }

    get queryParams() : SearchParams {
        if (this._queryParams == null) {
            console.warn("使用 queryParams 属性，需要在render方法中增加节点 <UseRouter attach={this} /> 。");
            return new SearchParams();
        } else {
            return this._queryParams;
        }
    }

    set queryParams(queryParams: URLSearchParams) {
        this._queryParams = new SearchParams(queryParams);
    }

    abstract get(name: string): any;

    set navigator(navigator: NavigateFunction) {
        this._navigator = navigator;
    }


    get location(): Location {
        if (this._location == null) {
            console.warn("使用 location 属性，需要在render方法中增加节点 <UseRouter attach={this} /> 。");
            return {} as Location;
        }
        return this._location as Location;
    }

    set location(location: Location) {
        this._location = location;
    }

    navigateTo(to: number): void;
    navigateTo(to: To, replace?: boolean, state?: any): void;
    navigateTo(to: To | number, replace?: boolean, state?: any) {
        if (this._navigator == null) {
            throw new Error(`使用此方法前，需要在render方法中增加节点 <UseRouter attach={this} /> 。`);
        }
        if (typeof to === "number") {
            this._navigator(to);
        } else {
            this._navigator(to, replace == null && state == null ? undefined : { replace, state });
        }
    }

    hasPermission(PermissionCode?: number): boolean;
    hasPermission(PermissionCode?: PermissionRequire): boolean;

    hasPermission(permission?: PermissionRequire | number): boolean {
        if (permission == null) {
            return true;
        } else if (typeof permission === "number") {
            return this.permissions.get(permission);
        }

        if (permission.children == null && permission.requirePermissions == null) {
            return true;
        }

        let result = true;
        let permissions = permission.children == null ? permission.requirePermissions as number[] : permission.children;

        if (permission.requireType == null || permission.requireType == "any") {
            for (let item of permissions) {
                if (item instanceof Object) {
                    result = this.hasPermission(item);
                } else {
                    result = this.permissions.get(item);
                }
                if (result) {
                    break;
                }
            }
        } else {
            result = true;
            for (let item of permissions) {
                if (item instanceof Object) {
                    result = result && this.hasPermission(item);
                } else {
                    result = result && this.permissions.get(item);
                }
            }
        }
        return result;
    }

    onRender(type?: "drawer" | "modal" | "stepModal") {};

    public showDrawer(props: DrawerProps) {
        this._drawer = props;
        const onClose = props.onClose;
        props.onClose = (e) => {
            this._drawer = undefined;
            if (onClose != null) {
                onClose(e);
            }
            this.onRender("drawer");
        }
        this.onRender("drawer");
    }

    public showStepModal(props: StepsFormModalProps): ModalUpdater<StepsFormModalProps> {
        return this.showModalByType("stepModal", props);
    }

    /**
     * 打开模态对话框，并返回一个处理后续操作的 {@link Promise} 对象。
     * 
     * @description 请勿使用 await 等待对话框结果，await 在用户多次点击确定或取消按钮时仅触发一次。
     * @param props 
     */
    public showPromiseModal(props: ModalProps) {
        return this.showPromiseModalByType("modal", props);
    }

    /**
     * 打开模态对话框，并返回一个处理后续操作的 {@link Promise} 对象。
     * 
     * @param props 
     * @description 请勿使用 await 等待对话框结果，await 在用户多次点击确定或取消按钮时仅触发一次。
     */
    public showPromiseStepModal(props: StepsFormModalProps) {
        return this.showPromiseModalByType("stepModal", props);
    }

    private showPromiseModalByType<T extends ModalProps>(modalType: "modal" | "stepModal", props: T): ModalPromise<ModalUpdater<T>, T> {

        const modalList = modalType === "modal" ? this._modals : this._stepModals;

        let resolveResult: ((value: any) => void) | null = null;
        let rejectResult: ((reason?: any) => void) | null = null;
        const result = new ModalPromise<any, T>((resolve, reject) => {
            resolveResult = resolve;
            rejectResult = rejectResult;
        });
        const updater: ModalUpdater<T> = {
            currentProps: props,
            destroy: () => {
                const index = modalList.indexOf(props);
                modalList.splice(index, 1);
                this.onRender(modalType);
            },
            update: (props) => {}
        };

        result.updater = updater;

        const onOk = props.onOk;
        const onCancel = props.onCancel;

        props.onOk = async (e) => {
            updater.modalResult = null;
            if (onOk != null) {
                updater.modalResult = Promise.resolve(onOk(e));
            }
            if (resolveResult != null) {
                resolveResult(updater);
            }
        }

        props.onCancel = async (e) => {
            updater.destroy();
            if (rejectResult != null) {
                rejectResult();
            }
            if (onCancel != null) {
                onCancel(e);
            }
        }

        if (modalType == "stepModal") {
            const stepModalProps = props as unknown as StepsFormModalProps;
            const onSubmit = stepModalProps.onSubmit;
            stepModalProps.onSubmit = async (e, values) => {
                if (onSubmit != null) {
                    updater.modalResult = Promise.resolve(onSubmit(e, values));
                } else {
                    updater.modalResult = values;
                }
                if (resolveResult != null) {
                    resolveResult(updater);
                }
            }
        }

        updater.update = this.updateModal.bind(this, modalType, updater as any, props);

        modalList.push(props);
        this.onRender(modalType);
        return result;
    }

    public showModal(props: ModalProps) {
        return this.showModalByType("modal", props);
    }

    private showModalByType<T extends ModalProps>(modalType: "modal" | "stepModal", props: T) {
        const onCancel = props.onCancel;

        const modalList = modalType === "modal" ? this._modals : this._stepModals;
        const result: ModalUpdater<T> = {
            currentProps: props,
            destroy: () => {
                const index = modalList.indexOf(props);
                modalList.splice(index, 1);
                this.onRender(modalType);
            },
            update: (props) => {}
        };
        result.update = this.updateModal.bind(this, modalType, result as any, props);

        props.onCancel = (e) => {
            if (onCancel) {
                onCancel(e);
            }
            result.destroy();
        }

        modalList.push(props);
        this.onRender(modalType);
        return result;
    }

    private updateModal<T extends ModalProps>(modalType: "modal" | "stepModal", updater: ModalUpdater<T>, oldProps: T, newProps: Partial<T>) {
        const modalList = modalType === "modal" ? this._modals : this._stepModals;
        const index = modalList.indexOf(oldProps);
        const finalProps = Object.assign(oldProps, newProps);
        modalList[index] = finalProps;
        updater.update = this.updateModal.bind(this, modalType, updater as any, finalProps);
        this.onRender(modalList == this._modals ? "modal" : "stepModal");
    }

    public closeModal(props: ModalProps) {
        if (this._modals.length > 0) {
            if (props == null) {
                this._modals.splice(this._modals.length - 1, 1);
            } else {
                const index = this._modals.indexOf(props);
                if (index >= 0) {
                    this._modals.splice(index, 1);
                }
            }
            this.onRender("modal");
        }
    }

    protected constructor() {
        if (AppBase.Instance != null) {
            throw new Error("请勿重复创建 AppBase 实例，每个页面应用只应存在一个 AppBase 实例！");
        }
        this._modals = [];
        this._stepModals = [];
        AppBase.Instance = this;
        Object.defineProperty(global, "app", { enumerable: true, writable: false, configurable: false, value: this });
    }

    public static wrapRouter(router: RouteWithPermission[]) {
        return [{
            key: Number.MIN_SAFE_INTEGER,
            path: "/",
            element: (<Application defaultPath="/app" />),
            showInSider: false,
            children: router
        }];
    }
}

interface ApplicationProps {
    defaultPath?: string
}

export function Application(props: ApplicationProps) {
    // app.navigator = useNavigate();
    // app.pathParams = useParams();
    // const queryParams = useSearchParams();
    // app.queryParams = queryParams[0];
    // app.location = useLocation();

    const defaultPath = props.defaultPath || "";
    const location = useLocation();
    // const realPath = useHref(defaultPath);
    // const basename = realPath == defaultPath ? "" : realPath.substring(0, realPath.length - defaultPath.length);

    if (defaultPath) {
        if (location.pathname == "" || location.pathname == "/") {
            return <Navigate to={defaultPath} />;
        }
    }
    return <Outlet />
}