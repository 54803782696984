

type DelayerCallback<T> = (...args: any[]) => T;

type ResolvePromise<T> = T extends Promise<infer P> ? ResolvePromise<P> : T;

type PromiseReturn<T> = T extends (...args: any[]) => infer R ? ResolvePromise<R> : never;


/**
 * 延时器
 */
export class Delayer<T extends DelayerCallback<any>> {

    timer: NodeJS.Timeout | null;

    promise: Promise<PromiseReturn<T>> | null = null;

    lastReject: ((reason?: any) => void) | null;

    callback: T;

    delayInMills: number;

    lastArgs: any[];

    name: string

    private constructor(name: string, callback: T, delayInMills: number) {
        this.name = name;
        this.timer = null;
        this.lastReject = null;
        this.callback = callback;
        this.lastArgs = [];
        this.delayInMills = delayInMills;
    }

    debounce(...args: Parameters<T>): Promise<PromiseReturn<T>> {
        this.lastArgs
        if (this.lastReject != null) {
            this.lastReject(new Error("计时器已被重置！" ));
        }
        if (this.timer != null) {
            clearTimeout(this.timer);
            this.lastReject = null;
        }
        
        return new Promise((resolve, reject) => {
            this.lastReject = reject;
            this.timer = setTimeout(() => {
                this.timer = null;
                this.lastReject = null;
                resolve(this.callback(...args));
            }, this.delayInMills);
        });
    };

    throttle(...args: Parameters<T>): Promise<PromiseReturn<T>> | null {
        this.lastArgs = args;
        if (this.timer == null) {
            return new Promise((resolve) => {
                this.timer = setTimeout(() => {
                    this.timer = null;
                    resolve(this.callback(...this.lastArgs));
                }, this.delayInMills);
            })
        } else {
            return Promise.reject(new Error(`${this.delayInMills}ms内的多次调用仅执行一次，若后续无其它调用，最终调用将使用本次传入的参数！`));
        }
    }

    static index: number = 1;
    /**
     * 创建一个防抖延时器。
     * @description
     * 在延时时间内重复触发，将导致计时器重置。
     * 例如：{@link delayInMills} = 200，则在第一次执行后，若 200ms 内再次调用，将从第2次调用时间起重新开始计时。
     * 
     * @param callback      防抖延时器回调函数。
     * @param delayInMills  延时时间。
     * @template T 最终返回值类型。
     */
    static debounce<T extends DelayerCallback<any>>(callback: T, delayInMills: number = 200): (...args: Parameters<T>) => Promise<PromiseReturn<T>> {
        let delayer = new Delayer<T>(`ddd${Delayer.index++}`, callback, delayInMills);
        return delayer.debounce.bind(delayer);
    }

    /**
     * 创建一个节流延时器。
     * @description
     * 在设定的延迟时间内仅执行一次回调函数；
     * 例如：创建时指定延时时间为200ms，在 200ms 内无论调用多少次返回的函数，都会在 200ms 后执行回调函数，回调函数参数选用这 200ms 内最后一次调用时传入的参数。
     * 
     * @param callback      节流延时器回调函数。
     * @param delayInMills  延时时间。
     */
    static throttle<T extends DelayerCallback<any>>(callback: T, delayInMills: number = 200): (...args: Parameters<T>) => Promise<PromiseReturn<T>> | null {
        let delayer = new Delayer<T>(`ddd${Delayer.index++}`, callback, delayInMills);
        return delayer.throttle.bind(delayer);
    }
}