import { FileUploadResult } from "@framework/component/quill-editor";
import { fetch } from "@root/app";
import { CACHE, HD } from "@root/constants";

/**
 * 类型（1-课程；2-用户；3-活动；4-学习资料；5-比赛；6-项目；7-轮播；8-教学课程；9-创业; 10-公告）
*/
export enum FileTypeId {
    COURSE = "1",
    USER = "2",
    ACTIVITY = "3",
    LEARN = "4",
    RACE = "5",
    PROJECT = "6",
    BANNER = "7",
    TEACHING = "8",
    ENTERPRISE = "9",
    NOTICE = "10",
    SCHOOL = "11",
    NOTE = "12"
}

export namespace FileUploadApi {
    /**
     * 
     * @param file 上传的图片文件。
     * @returns 上传成功后返回图片的url地址。
     */
    export async function uploadImage(file: File) {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);
        return fetch.postFormData<string>("/api/system/upload/image", formData);
    }

    export async function uploadFile(file: File) {
        let formData: FormData = new FormData()
        formData.append("file", file, file.name);
        return fetch.postFormData<string>("/api/system/upload/file", formData)
    }

    // 公告富文本
    export async function upload(file: File) {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);
        formData.append("typeId", '10')
        const url = await fetch.postFormData<string>("/api/system/upload/image", formData);
        const result: FileUploadResult = {
            url: url,
            thumbUrl: `${CACHE}${url}`,
            originUrl: `${HD}${url}`,
        }
        return result;
    }

    /**
     * 
     * @param typeId
     * 类型（1-课程；2-用户；3-活动；4-学习资料；5-比赛；6-项目；7-轮播；8-教学课程；9-创业; 10-公告）
     */
    export function requestForUploader(options: any) {
        const { onProgress, onError, onSuccess, file } = options;
        onProgress != null && onProgress({ percent: 99 });
        uploadImage(file).then(link => {
            onSuccess != null && onSuccess(link);
        }).catch(reason => {
            onError != null && onError({ name: "requestForUploader", message: reason })
        })
    }

    /**
    * 
    * @param typeId
    * 类型（1-课程；2-用户；3-活动；4-学习资料；5-比赛；6-项目；7-轮播；8-教学课程；9-创业; 10-公告）
    */
    export function requestFileUploader(options: any) {
        const { onProgress, onError, onSuccess, file } = options
        onProgress != null && onProgress({ percent: 99 });
        uploadFile(file).then(link => {
            onSuccess != null && onSuccess(link);
        }).catch(reason => {
            onError != null && onError({ name: "requestFileUploader", message: reason })
        })
    }
}