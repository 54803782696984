

import { SortedList } from "./sorted-list";
import { EncryptUtil } from "./encrypt-util";


export type Method = "GET" | "PUT" | "POST" | "DELETE" | "PATCH";

export type InterceptorType = "request" | "response" | "exception";

/**
 * 请求客户端
 */
export class FetchClient {

    #encrypt: boolean;
    #lastRequestOrder: number;
    #lastResponseOrder: number;
    #lastExceptionOrder: number;

    private interceptors: SortedList<IInterceptor> = new SortedList({
        compare(x: IInterceptor, y: IInterceptor): number {
            if (x.type === y.type) {
                return x.order <= y.order ? 1 : -1;
            } else {
                return x.type == "request" ? -1 : (x.type == "exception" ? 1 : (y.type === "request" ? 1 : -1));
            }
        }
    });

    public get encrypt(): boolean {
        return this.#encrypt;
    }

    public set encrypt(encrypt: boolean) {
        this.#encrypt = encrypt;
    }

    public constructor() {
        this.#encrypt = false;
        this.#lastResponseOrder = 0;
        this.#lastRequestOrder = 0;
        this.#lastExceptionOrder = 0;
    }

    /**
     * 添加一个请求拦截器，并指定拦截器的优先级。
     * 
     * @param processer 请求拦截处理程序。
     * @param order 优先序号，可以指定Number.MAX_SAFE_INTEGER~Number.MAX_SAFE_INTEGER之间的任何数字。
     */
    public addRequestInterceptor(processer: (request: RequestInfo) => RequestInfo | Promise<RequestInfo>, order?: number) {
        var interceptor: RequestInterceptor = {
            order: order == undefined ? this.#lastRequestOrder : order,
            type: "request",
            process: processer
        };
        this.addInterceptor(interceptor);
    }

    /**
     * 添加一个请求拦截器，并指定拦截器的优先级。
     * 
     * @param processer 请求拦截处理程序。
     * @param order 优先序号，可以指定Number.MAX_SAFE_INTEGER~Number.MAX_SAFE_INTEGER之间的任何数字。
     */
    public addResponseInterceptor(processer: <T>(response: ResponseResult<T>) => ResponseResult<T> | Promise<ResponseResult<any>>, order?: number) {
        var interceptor: ResponseInterceptor = {
            order: order == undefined ? this.#lastResponseOrder : order,
            type: "response",
            process: processer
        };
        this.addInterceptor(interceptor);
    }

    /**
     * 添加拦截器。
     * 
     * @param interceptor  拦截器
     */
    public addInterceptor(interceptor: IInterceptor) {
        this.interceptors.push(interceptor);
        if (interceptor.type == "request") {
            this.#lastRequestOrder++;
            this.#lastRequestOrder = Math.max(this.#lastRequestOrder, interceptor.order);
        }
        else if (interceptor.type == "response") {
            this.#lastResponseOrder++;
            this.#lastResponseOrder = Math.max(this.#lastResponseOrder, interceptor.order);
        } else if (interceptor.type == "exception") {
            this.#lastExceptionOrder++;
            this.#lastExceptionOrder = Math.max(this.#lastExceptionOrder, interceptor.order);
        }
    }

    /**
     * 删除指定的拦截器。
     * 
     * @param interceptor 需删除拦截器从零开始的索引位置。
     */
    public removeInterceptor(index: number): boolean {
        if (index >= 0 && index < this.interceptors.length) {
            this.interceptors.splice(index, 1);
            return true;
        }
        return false;
    }

    public delete<T>(url: string, params?: any, headers?: Record<string, string>, encrypt: boolean | null = null): Promise<T> {
        return this.executeAs<T>(url, "DELETE", params, headers);
    }

    public get<T>(url: string, params?: any, headers?: Record<string, string>, encrypt: boolean | null = true): Promise<T> {
        return this.executeAs<T>(url, "GET", params, headers);
    }

    public patch<T>(url: string, params?: any, headers?: Record<string, string>, encrypt: boolean | null = true): Promise<T> {
        return this.executeAs<T>(url, "PATCH", params, headers);
    }

    public post<T>(url: string, params?: any, headers?: Record<string, string>, encrypt: boolean | null = true): Promise<T> {
        return this.executeAs<T>(url, "POST", params, headers);
    }

    public put<T>(url: string, params?: any, headers?: Record<string, string>, encrypt: boolean | null = true): Promise<T> {
        return this.executeAs<T>(url, "PUT", params, headers);
    }

    public postFormData<T>(url: string, formData: FormData, headers?: Record<string, string>, encrypt: boolean | null = true): Promise<T> {
        return this.executeAs<T>(url, "POST", formData, headers);
    }

    public async executeAs<T>(url: string, method: Method = "GET", params?: FormData | Record<string, string> | null, headers?: Record<string, string>, encrypt: boolean | null = null): Promise<T> {
        let notNullHeaders: Record<string, string> = headers === undefined ? {} : headers;
        let body: FormData | string | null = null;
        if (method !== "GET") {
            notNullHeaders["Content-Type"] = "application/x-www-form-urlencoded";
        }
        
        if (params instanceof FormData) {
            delete notNullHeaders["Content-Type"];
            body = params as FormData;
        } else if (params !== undefined && params !== null) {
            let searchParams = new URLSearchParams();
            Object.keys(params).forEach(key => {
                if (params[key] != null) {
                    searchParams.set(key, params[key]);
                }
            })
            body = searchParams.toString();
        }

        let doEncrypt: boolean = (encrypt === null ? this.#encrypt : encrypt);
        var request: Promise<RequestInfo> = Promise.resolve({
            cache: "no-cache",
            credentials: "omit",
            headers: notNullHeaders,
            integrity: "",
            keepalive: false,
            method: method,
            mode: "cors",
            redirect: "manual",
            referrer: "",
            referrerPolicy: "no-referrer",
            body: method === "GET" ? undefined : body,
            url: method === "GET" && body != null && body != "" ? `${url}?${body}` : url,
            encrypt: doEncrypt,
            key: doEncrypt ? EncryptUtil.getRandomKey() : undefined
        });
        // 执行请求拦截器
        for (var interceptor of this.interceptors) {
            if (interceptor.type === "request") {
                request = request.then((interceptor as RequestInterceptor).process.bind(interceptor));
            }
        }

        return request.then((finalRequest) => {
            if (finalRequest.method == "GET") {
                finalRequest.url = finalRequest.body == null ? finalRequest.url : (finalRequest.url + "?" + finalRequest.body);
            }
            return fetch(finalRequest.url, finalRequest).then(res => {
                let result = new ResponseResult<T>(res, finalRequest.encrypt);
                result.key = finalRequest.key;
                return result;

            });
        }).then(responseResult => {
            let promise = Promise.resolve(responseResult);
            for (var interceptor of this.interceptors) {
                if (interceptor.type === "response") {
                    promise = promise.then((interceptor as ResponseInterceptor).process.bind(interceptor));
                }
            }
            return new Promise<ResponseResult<T>>((resolve, reject) => {
                promise.then(value => {
                    resolve(value);
                }).catch((reason) => {
                    let exceptionPromise = Promise.resolve(reason);
                    for (var interceptor of this.interceptors) {
                        const processor = interceptor as ExceptionInterceptor;
                        if (interceptor.type === "exception") {
                            exceptionPromise = exceptionPromise.then((reason) => {
                                processor.process(responseResult, reason);
                                return reason;
                            });
                        }
                    }
                    exceptionPromise.then(reject);
                })
            });
        }).then((value: ResponseResult<T>) => value.data as T);
    }
}

/**
 * 表示接口请求信息。
 */
export class RequestInfo implements RequestInit {
    /**
     * 请求体 (所有请求参数均在此处)
     */
    body?: BodyInit | null;
    /**
     * 缓存方式
     */
    cache?: RequestCache;
    /**
     * 
     */
    credentials?: RequestCredentials = "omit";
    headers?: HeadersInit;
    integrity?: string;
    keepalive?: boolean = true;
    method?: Method = "GET";
    mode?: RequestMode = "cors";
    redirect?: RequestRedirect;
    referrer?: string;
    referrerPolicy?: ReferrerPolicy;
    signal?: AbortSignal | null;
    window?: any;
    key?: string;
    encrypt: boolean = true;
    url: string = "";
}

/**
 * 表示接口响应数据。
 */
export class ResponseResult<T> {
    /**
     * 执行结果代号
     */
    public code: string | number = 0;
    /**
     * 执行结果提示信息。
     */
    public message?: string;
    /**
     * 当前响应的原文。
     */
    public response: Response;
    /**
     * AES 加密使用的密钥。
     */
    public key?: string;
    /**
     * 是否为加密数据。
     */
    public encrypt: boolean;
    /**
     * 返回数据。
     */
    public info?: T;
    /**
     * 返回数据。
     */
    public data?: T;

    public originData?: string;
    /**
     * 是否需要进行动态授权。
     */
    public get needAuthorize(): boolean {
        return (this.response !== undefined && this.response?.status === 303);
    }

    /**
     * 是否需要确认后继续操作。
     */
    public get needConfirm(): boolean {
        return (this.response !== undefined && this.response?.status === 302);
    }

    constructor(response: Response, encrypt: boolean) {
        this.encrypt = encrypt;
        this.response = response;
    }
}

export interface IInterceptor {
    /**
     * 执行序号，可以指定Number.MAX_SAFE_INTEGER~Number.MAX_SAFE_INTEGER之间的任何数字。
     */
    readonly order: number;
    /**
     * 拦截器类型："request" | "response"
     */
    readonly type: InterceptorType;

    // /**
    //  * 拦截器处理程序。
    //  * 
    //  * @param result 当前正在处理的请求/响应数据。
    //  * @returns 拦截器处理结果。
    //  */
    // process<TData, T extends (RequestInfo | ResponseResult<TData>)>(result: T): T | Promise<T>;
}

export abstract class RequestInterceptor implements IInterceptor {
    public readonly order: number;
    public readonly type: InterceptorType;

    protected constructor(order?: number) {
        this.type = "request";
        this.order = order == null ? 0 : order;
    }
    /**
     * 请求拦截器处理程序。
     * 
     * @param request 当前正在处理的请求数据。
     * @returns 拦截器处理结果。
     */
    public abstract process(request: RequestInfo): RequestInfo | Promise<RequestInfo>;
}


export abstract class ResponseInterceptor implements IInterceptor {
    public readonly order: number;
    public readonly type: InterceptorType;

    protected constructor(order?: number) {
        this.type = "response";
        this.order = order == null ? 0 : order;
    }

    /**
     * 响应拦截器处理程序。
     * 
     * @param result 当前正在处理的相应数据。
     * @returns 拦截器处理结果。
     */
    public abstract process<T>(result: ResponseResult<T>): ResponseResult<T> | Promise<ResponseResult<T>>;
}

export abstract class ExceptionInterceptor implements IInterceptor {
    public readonly order: number;
    public readonly type: InterceptorType;

    protected constructor(order?: number) {
        this.type = "exception";
        this.order = order == null ? 0 : order;
    }

    /**
     * 异常处理程序。
     * 
     * @param result 当前正在处理的相应数据。
     * @param reason 错误原因。
     * @returns 拦截器处理结果。
     */
     public abstract process<T>(result: ResponseResult<T>, reason: Error): void | ResponseResult<T>;
}