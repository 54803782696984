

export const PUBLIC_KEY = 
`-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxWXNLChPDUakgvbSp9Ym\
GpEv91+me3pX7EViYjohSX8mQ6AVwoN5XcHQvlKZF+WMEDZLgXTUIM5IDo2Zx2r5\
EP3o/MGkGf7bneUBP0gjhsE6T89SI+LUkNgjWyT87lrqZ5qHUtLACAuq5C7uP1Yc\
8v9+L/tqrfJeQ1Z4sP0z7kHU6fd87oMAQYR97BkF5I7pnRuhEAIv4rCM90CzxUeO\
X7qdc0uoZbfc9lfsdHH1SH0Wh3FZATrsJagDMFMjzjR+Y0P/6IX/uymzGZMu5Tk+\
FSWIfQXadu8aYRiJu9oGyJu3nXSmJnK+nh/8XXfZ28oWR3Z8A8Yxy+uPgw85mgJe\
7wIDAQAB
-----END PUBLIC KEY-----`;




export const HD = `/hd`;
export const CACHE = `/cache`;