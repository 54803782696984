

export namespace storage {
    export function getObjectFromLocal<T extends Object>(key: string, defaultValue: T = {} as T): T {
        return getObjectFromStorage(localStorage, key, defaultValue);
    }

    export function getArrayFromLocal<T>(key: string, defaultValue: Array<T> = []): T[] {
        return getArrayFromStorage(localStorage, key, defaultValue);
    }

    export function getStringFromLocal(key: string, defaultValue: string = ""): string {
        return getStringFromStorage(localStorage, key, defaultValue);
    }

    export function getObjectFromSession<T extends Object>(key: string, defaultValue: T = {} as T): T {
        return getObjectFromStorage(sessionStorage, key, defaultValue);
    }

    export function getArrayFromSession<T>(key: string, defaultValue: Array<T> = []): T[] {
        return getArrayFromStorage(sessionStorage, key, defaultValue);
    }

    export function getStringFromSession(key: string, defaultValue: string = ""): string {
        return getStringFromStorage(sessionStorage, key, defaultValue);
    }

    function getObjectFromStorage<T extends Object>(storage: Storage, key: string, defaultValue: T = {} as T): T {
        let value = localStorage.getItem(key);
        let result: T = defaultValue == undefined ? {} as T : defaultValue;;
        if (value != null) {
            try {
                if (value.startsWith("{") && value.endsWith("}")) {
                    result = JSON.parse(value);
                } else {
                    console.warn(`从 storage 中获取到的数据类型有误, 期望类型为 Object，key: ${key} value: ${result}`);
                }
            } catch (error) {
                console.warn(`反序列化数据时发生错误，key: ${key} value: ${value} error: ${error}`);
            }
        }
        return result;
    }

    function getArrayFromStorage<T>(storage: Storage, key: string, defaultValue: Array<T> = []): T[] {
        let value = localStorage.getItem(key);
        let result = defaultValue;
        if (value != null) {
            try {
                if (value.startsWith("[") && value.endsWith("]")) {
                    result = JSON.parse(value);
                } else {
                    console.warn(`从 storage 中获取到的数据类型有误, 期望类型为 Array ，key: ${key} value: ${result}`);
                }
            } catch (error) {
                console.warn(`反序列化数据时发生错误，key: ${key} value: ${value} error: ${error}`);
            }
        }
        return result;
    }

    function getStringFromStorage(storage: Storage, key: string, defaultValue: string = ""): string {
        let result = storage.getItem(key);
        return result == undefined ? defaultValue : result;
    }

    export function saveInLocal(key: string, value: any) {
        saveInStorage(localStorage, key, value);
    }

    export function saveInSession(key: string, value: any) {
        saveInStorage(sessionStorage, key, value);
    }

    function saveInStorage(storage: Storage, key: string, value: any) {
        if (typeof value == "object") {
            storage.setItem(key, JSON.stringify(value));
        } else {
            storage.setItem(key, value.toString());
        }
    }
}