import { fetch } from "@root/app";
import { HomeApi } from "./home";

export namespace DetailApi {

    export interface Project extends HomeApi.Project {
        isFocus: boolean
        levelName: string
        principalPhone: string
        initiationTime: number
        createUserName: string
        lastUpdateUserName: string
        enterpriseName: string
        businessBookPath: string
        firstTeacherPhone: string
        teacherNames: string
        profileContent: string
        videoPath: string
        pptPath: string
        paperResultPath: string
        annexPath: string
        paper: any[]
        intellectualProperty: any[]
    }

    export interface Teaching extends HomeApi.Teaching {
        fundAmount: number
        createUserName: string
        lastUpdateUserName: string
        solveProblemContent: string
        fundRemarks: string
        technicalRouteContent: string
        innovationContent: string
        profileContent: string
        progressList: any[]
        achievementList: any[]
        annexPath?: string
    }

    export interface Activity extends HomeApi.Activity {
        enrollMaxQty?: number
        profileContent?: string
        ruleContent?: string
        annexPath?: string
    }

    export interface Race extends HomeApi.Race {
        awardsContent?: string
        profileContent?: string
        requireContent?: string
        annexPath?: string
        historyAnnexPath?: string
    }

    export interface Inform extends HomeApi.Inform {
        content?: string
    }

    export interface Data extends HomeApi.Data {
        profileContent?: string
        annexPath?: string
    }

    export function getActivityDetail(id: string) {
        return fetch.get<Activity>(`/api/client/activity/${id}`)
    }

    export function getDataDetail(id: string) {
        return fetch.get<Data>(`/api/client/learn-data/${id}`)
    }

    export function getInformDetail(id: string) {
        return fetch.get<Inform>(`/api/client/notice/${id}`)
    }

    export function getProjectDetail(id: string) {
        return fetch.get<Project>(`/api/client/program/${id}`)
    }

    export function getRaceDetail(id: string) {
        return fetch.get<Race>(`/api/client/race/${id}`)
    }

    export function getTeachingDetail(id: string) {
        return fetch.get<Teaching>(`/api/client/teaching-research/${id}`)
    }
}