

import { Carousel } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import styles from "@styles/layout.less";
import { HomeApi } from "@api";
import { HD } from "@root/constants";

const navLinkClass = ({ isActive }: { isActive: boolean }) => {
    return isActive ? styles.currentLink : styles.link
}

const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "580px",
    overflow: "hidden",
    cursor: "pointer"
};

const types = {
    activity: 1,
    race: 2,
    project: 3,
    research: 4,
    learnInfo: 5,
    inform: 6
} as const

export default function (props: any) {

    const navigate = useNavigate()
    const [banner, setBanner] = useState<HomeApi.Banner[]>([])

    useEffect(() => {
        HomeApi.getBanner().then(res => {
            setBanner(res)
        })
    }, [])

    const navigatePage = (typeId?: number, relevancyId?: string) => {
        if (!typeId) return
        switch (typeId) {
            case types.activity:
                return navigate(`/app/activity/${relevancyId}`);
            case types.race:
                return navigate(`/app/race/${relevancyId}`);
            case types.project:
                return navigate(`/app/project/${relevancyId}`);
            case types.research:
                return navigate(`/app/teaching/${relevancyId}`);
            case types.learnInfo:
                return navigate(`/app/data/${relevancyId}`);
            case types.inform:
                return navigate(`/app/inform/${relevancyId}`);
        }
    }

    return (
        <div className={styles.layout}>
            <header className={styles.header}>
                <Carousel
                    autoplay
                    dots={false}
                >
                    {
                        banner.map(item => (
                            <div key={item.id} onClick={() => navigatePage(item.typeId, item.relevancyId)}>
                                <img style={imageStyle} src={HD + item.coverPath} />
                            </div>
                        ))
                    }
                </Carousel>
                <div className={styles.menu}>
                    <NavLink className={navLinkClass} to={"/app/"}>首页</NavLink>
                    <NavLink className={navLinkClass} to={"/app/activity"}>活动</NavLink>
                    <NavLink className={navLinkClass} to={"/app/race"}>比赛</NavLink>
                    <NavLink className={navLinkClass} to={"/app/project"}>项目</NavLink>
                    <NavLink className={navLinkClass} to={"/app/teaching"}>教研项目</NavLink>
                    <NavLink className={navLinkClass} to={"/app/data"}>资料</NavLink>
                </div>
            </header>
            <div className={styles.content} >
                <Outlet />
            </div>
        </div>
    )
}