

import { pki } from "node-forge";

import { FetchClient } from "./utils";
import { BytesEncodeMode, EncryptUtil } from "./utils/encrypt-util";
import { DecryptInterceptor, DynamicAuthorizeInterceptor, EncryptInterceptor, ParseResponseInterceptor } from "./fetch-interceptors";

export interface FetchContext {
    bytesEncodeMode: BytesEncodeMode

    get fetch(): FetchClient;
}

export class FetchContextImpl implements FetchContext {
    bytesEncodeMode: BytesEncodeMode;
    private publicKey: pki.rsa.PublicKey;

    private _fetch: FetchClient;
    private interceptorAdded = false;

    constructor(publicKey: string) {
        this.bytesEncodeMode = "Hex";
        this._fetch = new FetchClient();
        this._fetch.encrypt = false;
        this.publicKey = EncryptUtil.parsePublicKey(publicKey);
    }

    get fetch(): FetchClient {
        if (!this.interceptorAdded) {
            this._fetch.addInterceptor(new EncryptInterceptor(this.bytesEncodeMode, this.publicKey));
            this._fetch.addInterceptor(new ParseResponseInterceptor());
            this._fetch.addInterceptor(new DecryptInterceptor(this.bytesEncodeMode, this.publicKey));
            this._fetch.addInterceptor(new DynamicAuthorizeInterceptor());
            this.interceptorAdded = true;
        }
        return this._fetch;
    }
}

