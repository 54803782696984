

import moment, { Moment } from "moment";

/**
 * 数据转换接口
 * @template T1 用户原始数据类型
 * @template T2 组件中使用的数据类型
 */
export interface Converter<T1, T2> {
    /**
     * 将用户原始数据转换为组件中使用的数据。
     * 
     * @param value 用户原始数据。
     * @param values 在 form 表单中使用时，为该 form 表单当前所有字段的值。
     */
    convert(value?: T1, values?: Record<string, any>): T2 | null;

    /**
     * 将组件数据转换为用户数据。
     * 
     * @param value 组件传入的数据。
     * @param values 在 form 表单中使用时为该 form 表单当前所有字段的值。
     * @param target 在 form 表单中使用时为该 form 表单最终提交的对象。
     */
    convertBack(value?: T2, values?: Record<string, any>, target?: Record<string, any>): T1 | null;
}

export class BooleanConverter<T> implements Converter<T, boolean> {

    private trueValue: T;

    private falseValue: T;

    constructor(trueValue: T, falseValue: T) {
        this.trueValue = trueValue;
        this.falseValue = falseValue;
    }
    convert(value?: T, values?: Record<string, any>): boolean | null {
        return value == null ? null : (value == this.trueValue);
    }

    convertBack(value?: boolean, values?: Record<string, any>): T | null {
        return value == null ? null : (value ? this.trueValue : this.falseValue);
    }

}

export class TimestampConverter implements Converter<number, Moment> {

    /**
     * 使用 {@link Converter["convert"]} 转换时，若传入的参数为空时，返回此值。
     */
    destDefault?: Moment | (() => Moment);

    /**
     * 使用 {@link Converter["convertBack"]} 转换时，若传入的参数为空时返回此值。
     */
    srcDefault?: number | (() => number);

    /**
     * 创建一个新的时间戳转换程序。
     * 
     * @param destDefault 使用 {@link Converter["convert"]} 转换时，若传入的参数为空时返回的值。
     * @param srcDefault  使用 {@link Converter["convertBack"]} 转换时，若传入的参数为空时返回的值。
     */
    public constructor(destDefault?: Moment | (() => Moment), srcDefault?: number | (() => number)) {
        this.destDefault = destDefault;
        this.srcDefault = srcDefault;
    }

    convert(value?: number, values?: Record<string, any>): Moment | null {
        const val = Number(value);
        if (val == null || Number.isNaN(val)) {
            if (this.destDefault == null) {
                return null;
            } else if (this.destDefault instanceof Function) {
                return this.destDefault();
            } else {
                return this.destDefault;
            }
        }
        return moment(val);
    }

    convertBack(value?: Moment, values?: Record<string, any>): number | null {
        if (value == null) {
            if (this.srcDefault == null) {
                return null;
            } else if (this.srcDefault instanceof Function) {
                return this.srcDefault();
            } else {
                return this.srcDefault;
            }
        }
        return value.valueOf();
    }

}

export class IdsConverter<T extends (string | number)> implements Converter<string, T[]> {
    private separator: string;
    private parser?: (value: string) => T;
    /**
     * 创建一个新的分隔字符列表到数组的转换程序。
     * 
     * @param separator 用来分隔用户数据的分隔符号，默认为 ","。
     * @param parser 对拆分后的每项元素进行转换的方法。
     */
    constructor(separator?: string, parser?: (value: string) => T) {
        this.separator = separator == null ? "," : separator;
        this.parser = parser;
    }

    convert(value?: string | undefined, values?: Record<string, any> | undefined): T[] | null {
        if (value == null || value == "") {
            return undefined as any;
        }
        let results: string[] = value.split(this.separator);
        if (this.parser == null) {
            return results as T[];
        } else {
            return results.map(item => this.parser && this.parser(item)) as T[];
        }
    }

    convertBack(value?: T[] | undefined, values?: Record<string, any> | undefined, target?: Record<string, any> | undefined): string | null {
        if (value == null || value.length == 0) {
            return null;
        }
        return value.join(this.separator);
    }

}

/**
 * 
 */
export class MinuteConverter implements Converter<number, Moment> {
    
    /**
     * 使用 {@link Converter["convert"]} 转换时，若传入的参数为空时，返回此值。
     */
    destDefault?: Moment | (() => Moment);

    /**
     * 使用 {@link Converter["convertBack"]} 转换时，若传入的参数为空时返回此值。
     */
    srcDefault?: number | (() => number);

    /**
     * 创建一个新的时间戳转换程序。
     * 
     * @param destDefault 使用 {@link Converter["convert"]} 转换时，若传入的参数为空时返回的值。
     * @param srcDefault  使用 {@link Converter["convertBack"]} 转换时，若传入的参数为空时返回的值。
     */
    public constructor(destDefault?: Moment | (() => Moment), srcDefault?: number | (() => number)) {
        this.destDefault = destDefault;
        this.srcDefault = srcDefault;
    }

    convert(value?: number, values?: Record<string, any>): Moment | null {
        const val = Number(value);
        if (val == null || Number.isNaN(val)) {
            if (this.destDefault == null) {
                return null;
            } else if (this.destDefault instanceof Function) {
                return this.destDefault();
            } else {
                return this.destDefault;
            }
        }
        return moment({ year: 0, month: 0, date: 1, hour: Math.floor(val / 60), minute: val % 60, second: 0, millisecond: 0 });
    }

    convertBack(value?: Moment, values?: Record<string, any>): number | null {
        if (value == null) {
            if (this.srcDefault == null) {
                return null;
            } else if (this.srcDefault instanceof Function) {
                return this.srcDefault();
            } else {
                return this.srcDefault;
            }
        }
        return value.hour() * 60 + value.minute();
    }
}

export class RangeDateConverter<T extends object> implements Converter<T, [Moment | undefined, Moment | undefined]> {

    startField: keyof T;
    endField: keyof T;

    constructor(startField: keyof T, endField: keyof T) {
        this.endField = endField;
        this.startField = startField;
    }

    convert(value?: T, values?: T): [Moment | undefined, Moment | undefined] | null {
        const start = Number(value == null ? undefined : value[this.startField]);
        const end = Number(value == null ? undefined : value[this.endField]);
        const result:[Moment | undefined, Moment | undefined] = [undefined, undefined];
        if (!Number.isNaN(start)) {
            result[0] = moment(start);
        }
        if (!Number.isNaN(end)) {
            result[1] = moment(end);
        }
        return result;
    }

    convertBack(value?: [Moment | undefined, Moment | undefined], values?: T, target?: T): T | null {
        if (target != null) {
            if (value != null) {
                target[this.startField] = value[0] == null ? undefined : value[0].valueOf() as any;
                target[this.endField] = value[1] == null ? undefined : value[1].valueOf() as any;
            }
        }
        return target as T;
    }

} 

export class RangeMinuteConverter<T extends object> implements Converter<T, [Moment | undefined, Moment | undefined]> {
    startField: keyof T;
    endField: keyof T;

    constructor(startField: keyof T, endField: keyof T) {
        this.endField = endField;
        this.startField = startField;
    }

    convert(value?: T | undefined, values?: Record<string, any> | undefined): [moment.Moment | undefined, moment.Moment | undefined] | null {
        const start = Number(value == null ? undefined : value[this.startField]);
        const end = Number(value == null ? undefined : value[this.endField]);
        const result:[Moment | undefined, Moment | undefined] = [undefined, undefined];
        if (!Number.isNaN(start)) {
            result[0] = moment().startOf("day").add(start, "minute");
        }
        if (!Number.isNaN(end)) {
            result[1] = moment().startOf("day").add(end, "minute");
        }
        return result;
    }

    convertBack(value?: [moment.Moment | undefined, moment.Moment | undefined], values?: T, target?: T): T | null {
        if (target != null) {
            if (value != null) {
                target[this.startField] = value[0] == null ? undefined : value[0].hour() * 60 + value[0].minute() as any;
                target[this.endField] = value[1] == null ? undefined : value[1].hour() * 60 + value[1].minute() as any;
            }
        }
        return target as T;
    }

}

export const DEFAULT_IDS_CONVERTER = new IdsConverter();